import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'

class Generic extends React.Component {

  render() {

    return (
      <Layout>
        <Helmet title="श्री गुरूगजाननलीला ग्रंथ - येथे सांगावेसे वाटते" />
        <HeaderGeneric title="श्री गुरूगजाननलीला ग्रंथ" adhyaay='येथे सांगावेसे वाटते'/>
        <div id="main">
          <section id="content" className="main">
            <p>
१)‌ मूळ ग्रंथच श्रेष्ठ, तो वाचला तरच सार्थक होईल, बाकीचे दुय्यम त्यामुळे तितुके फळ मिळणार नाही, म्हणून दुसरा ग्रंथ वाचू नये असे मानू नये. कारण जे काही तुम्ही वाचाल ते मनापासून वाचाल, अंतरंगाच्या ओढीने वाचाल, भावयुक्त वाचाल तर फळ निश्चित मिळेल. तुम्हाला मानसिक समाधान लाभेलच लाभेल. ईश्वराची जशी विविध रूपे आहेत तसेच ग्रंथांच्या बाबतीत आहे.< br />
< br />
२)‌ एकरूपतेला, नियमाने करण्याला, सातत्याला, तसेच आत्मीयतेने, प्रेमळ अंत:करणाने करण्याला महत्व आहे. अशी कृती प्रभावी ठरते.< br />
< br />
३)‌ सारा खेळ श्रद्धेवर चालतो. निष्ठेवर चालतो. प्रारब्ध भोग देवादिकांनाही चुकले नाहीत. आपली गतजन्मीची जी कर्मफळे शिल्लक राहिली आहेत व ह्या जन्मातल्या काही कर्मांची फळे भोगण्यासाठी आपला जन्म आहे. प्राक्तनातले भोगावेच लागणार. प्राक्तन भोगताना सुसह्य वाटावे म्हणूनच उपासना. केवळ भोग - सुखदुःखे विसरण्यासाठी आपले लक्ष भक्तीमार्गात केंद्रीत केल्यास तुम्ही सहज भावे जगू शकता. मुद्दाम करावे लागत नाही.< br />
< br />
४)‌ प्रेरणांचा अविरत प्रवाह चालू असतो. ह्या प्रेरणा आपल्या विचारांवर, गतकालच्या कर्मांवर, सभोवतालावर अवलंबून असतात. प्रेरणांना जास्त, फाजील महत्व दिल्यास माणूस भांबावतो. सगळ्याच प्रेरणा फलदायी होत नाहीत. अपयश आल्यास हताश होण्याचे योग येतात. त्या उलट यश आल्यास अहंकार, “मी” पणा जागृत होतो, जो सदैव धोकेदायक ठरतो. त्यापेक्षा आदेशानुसार जगावे. पाप-पुण्य, यश-अपयश, सारेच विधात्याचे, ह्या जगात माझे काही नाही अशा अकर्ता भावाने जगावे.< br />
< br />
५)‌ प्रेरणा व आदेश कसे ओळखाल?–	अंतरंगाच्या उर्मीतून जेव्हा आज्ञार्थ निघतो, हुकुमात्मक शब्द बाहेर पडतात तो आदेश. उदा. असे कर. तसे करू नकोस. प्रेरणा म्हणजे केवळ करावेसे वाटणे. त्यातूनच महत्वाकांक्षा निर्माण होते. ही महत्वाकांक्षा चित्ताला जाळते.< br />
< br />
६)‌ जीवनात समाधानी वृत्तीला फार महत्व आहे. समाधानी वृत्तीत आनंद आहे. जीवनात आनंद मिळवायचा असेल तर माणूस समाधानी असावयास पाहिजे. काही माणसे उपजतच समाधानी असतात. तर काही चिंतनाने, नामस्मरणाने, तपश्वर्येने तर काही आज्ञेनुसार वागण्याने समाधानी होतात.< br />
< br />
७)‌ समाधानी माणूस स्थिर वृत्तीचा असतो. शांत स्वभावाचा असतो. चित्तशांतीने जगू शकतो. प्रारब्ध भोग सहज भोगतो व अकर्त्या भावाने जगतो.< br />
< br />
८)‌ ईर्षेने भक्ती करू नये. ईश्वराची स्तुती करण्यासाठी, ईश्वराचे अनंत उपकार मानण्यासाठी, त्याचेशी मनमोकळेपणाने बोलण्यासाठी भक्ती करावी.< br />
< br />
९)‌ ईश्वरास सदैव रडगाणे सांगू नये. आपण कशा स्थितीत आहोत ते त्याला माहितच असते. तेव्हा ईश्वराशी बोलावयाचे असल्यास ईश्वरास म्हणावे - “तू किती थोर आहेस । तू किती दयाळू आहेस । तू किती कृपाळू आहेस ! माझ्या सारख्या अवगुणी माणसास तू जवळ करतोस. खरोखर तुझे अंतःकरण विशाल आहे. जगाने जरी मला झिडकारले तरी सुद्धा तू मला जवळ करतोस. नुसते अपराध पोटात घालत नाहीस तर तू मला वेळोवेळी सावध करतोस. तुझ्या भक्तीत रमवतोस. देहदुःखाचा विसर पाडतोस.”< br />
< br />
१०)‌ विषयी, व्यसनी असू नये. त्याने मेंदूवरचा तोल जातो व अघटित कृत्ये करण्याचा प्रसंग येतो. हे टाळण्यासाठी, कमी करण्यासाठी मन चांगल्या ठिकाणी रमवावे. चांगले वाईट कळण्यासाठी कली युगात नामस्मरण आवश्यक आहे. “नामच माझा गुरू । तेणे भवसागर तरू ॥”< br />
< br />
११)‌ वासनामय जगू नये. वासनेने बरबटू नये. वासना असंख्य आहेत. जाळे विशाल आहे. जीवनात नामाचा, सत्संगतीचा, संतांचा, भजन कीर्तन श्रवणाचा संबंध नसेल तर माणसाचे जीवन पशुतूल्य होईल.< br />
< br />
१२)‌ नरदेह कशासाठी ? नरदेह आत्मचिंतनासाठी, परमार्थासाठी. चराचरात ईश्वर पहावा. दुजाभाव विसरावा. सर्वांवर शुद्ध भावनेने, निःस्वार्थ भावाने प्रेम करा.< br />
< br />
१३)‌ “तू आहेस, मी नाही” ह्या भावनेने प्रपंच केल्यास प्रपंचात उत्तम प्रकारे परमार्थ साधता येतो. प्रपंच वेगळा, परमार्थ वेगळा असे मानू नये.परमार्थासाठी मुद्दाम वनात जायची आवश्यकता नाही. प्रपंचात राहून परमार्थ करता येतो. उदा. वसिष्ठ मुनी, जनक राजा.< br />
< br />
१४)‌ जीवनात चित्तशुद्धीला फार महत्व आहे. नामस्मरणामुळे आपलेच दोष आपल्याला कळू लागतात. नामाच्या प्रभावामुळे आपले चित्त शुद्ध राहू शकते. आपणास करावयाचे फक्त एकच. ते म्हणजे “आपण ऐकावे नामाचे । कधी ऐकु नये मनाचे ॥”< br />
< br />
१५)‌ आपले माता-पिता, धर्म, जात, गुरू, पती, पत्नी, रहाते घर, मायभूमीस कधीहि तुलनेत उतरवू नये. दुसर्‍याचे वरवर दिसायला चांगले दिसते. बाकी प्रत्येकाला प्रत्येकाच्या परीने आपल्या कर्मानुसार ताप आहेच. तेव्हा ह्याचे चिंतन करून वेळ व्यर्थ दवडू नये. आपण आपले कर्तव्य कर्म करावे.< br />
< br />
१६)‌ नामजप व पारायण श्रद्धेने करावे. महाराज आपणाला दर्शन देतीलच अशा तळमळीने करावे. तळमळीला, श्रद्धेला फार महत्व आहे.< br />
< br />
१७)‌ नामजप व पारायण चालू असताना दुसर्‍यांशी बोलू नये. नामजपात झालेल्या प्रेरणा सांगण्यास व लिहून ठेवण्यास हरकत नाही.< br />
< br />
१८)‌ भक्तीमार्गात कधीही स्पर्धा करू नये. आपले कर्तव्य कर्म म्हणून करावे.< br />
< br />
१९)‌ ह्या ग्रंथाचे पारायण १ दिवसात, ३ दिवसात, ७ दिवसात वा २१ दिवसात पूर्ण होईल असे करावे. संकल्पाच्या वेळेस अध्याय ठरवून तशाच क्रमाने पारायण पूर्ण करावे. त्यात बदल करू नये. एक दिवसात पारायण करणे उत्तमच. ७ पारायणे, २१ पारायणे, १०८ पारायणे, १००८ पारायणे कुठचातरी शुद्ध हेतू मनात ठेवून करावी. निरपेक्ष वृत्तीने पारायण करणे जास्त चांगले. दशमी, एकादशी, द्वादशी असे तीन दिवसात ग्रंथाचे पारायण करावे. ज्यांना एका बैठकीत पारायण जमेल त्यांनी दर गुरूवारी करावे. तसेच< br />
< br />
	<p style={{'marginLeft': '35px', 'lineHeight': '5px'}}>१)		गुरू गजानन प्रकटदिन – माघ वद्य सप्तमी</p>
	<p style={{'marginLeft': '35px', 'lineHeight': '5px'}}>२)		श्रीराम नवमी</p>
	<p style={{'marginLeft': '35px', 'lineHeight': '5px'}}>३)		गुरू पोर्णीमा</p>
	<p style={{'marginLeft': '35px', 'lineHeight': '5px'}}>४)		गुरू गजानन महाराज पुण्यतिथी – ऋषीपंचमी</p>
	<p style={{'marginLeft': '35px', 'lineHeight': '5px'}}>५)		गुरूपुष्य नक्षत्र योगावर पारायण अवश्य करावे.</p>
पारायण पूर्णाहुतीच्या दिवशी वा सवडीनुसार यथाशक्ती ब्राह्मण भोजन घालावे. श्री गुरू महाराजांना आवडणारे पदार्थ करावेत. महाराज आपल्या घरी भोजनास आले आहेतच अशा भावात रहावे.< br />
< br />
२०)‌ प्रयत्नशाली असावे.  ध्येयवादी असावे. परंतु ईर्षाखोर नसावे. सारे महाराजांवर सोपवावे.< br />
< br />
२१)‌ प्रारब्ध भोग सहजगत्या भोगण्यासाठी श्रद्धेने नामजपाची, “गण गण गणात बोते” या मंत्राची व गुरू गजानन लीला वाचण्याची अत्यंत आवश्यकता आहे.< br />
< br />
२२)‌ ”नाम स्मरावे केव्हाही, कोठेही । तेणे भगवंत भेटे केव्हाही, कोठेही ॥” आपण अनुभवावे.< br />
< br />
२३)‌ भगवंताच्या नामाला जातीभेद नाही. नाम कुणीही भजावे. नामाने ईश्वराचे व्हावे.< br />
< br />
२४)‌ नामाला सोवळे-ओवळे, नामाला विटाळ नाही. मन ताजे तवाने, प्रफुल्लित, ईश्वरमय ठेवण्यासाठी लहानापासून ते थोरापावेतो, बाल, स्त्री, पुरूष, सर्वांस नाम उपयुक्त आहे.< br />
< br />
२५)‌ आपणा सर्वांस श्री गजानन महाराज यंचे दर्शन व्हावे ह्या शुद्ध हेतूने माझ्याकडून सरस्वतीने दृष्टांत देऊन तसेच माझे गुरू प. पू. पांडुरंग शास्त्री आठवले ह्यांच्या कृपाशिषाने १९८७ च्या माघ वद्य सप्तमी ते शिवरात्री पावेतो, म्हणजेच २० फेब्रुवारी १९८७ ते २६ फेब्रुवारी १९८७ असे सात दिवसात गुरू गजानन ग्रंथ लिहवून घेतला आहे. थोडाफार बदल करून तिसर्‍यांदा तिसर्‍या सप्ताहात (४ जून १९८९ ते १० जून १९८९) लिहिलेला ग्रंथ आपणांस सादर करीत आहे. कर्ता करवीता भगवंत. माझे काही नाही. न्यूनता वाटल्यास कृपया मला क्षमा करून सुधारून तसे वाचावे ही विनंती.< br />
< br />

</p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
